
import {mapActions} from 'vuex';
import i18n from "@/vueI18n";

export default {
 methods:{
   i18n(key, args) {
      return this.$t(key, args);
    },
        ...mapActions({
            setHeaderBg:'layout/setHeaderBg',
            setNavbar:'layout/setNavbar',
            setFooter:'layout/setFooter'
        }),
        
    },
    computed: {
      lang(){
        // debugger    
        return i18n.locale
      },
    },
    watch:{
      lang(){
        document.title =
          this.i18n('app.title') +
          ' | ' +
          this.i18n('drugDeal.common.about') 
      }
    },
    created(){
      document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('drugDeal.common.about') 
    },
    mounted(){
        this.setHeaderBg('bg-MulBerry')
        this.setNavbar('about')
        this.setFooter(true)
    }
}
